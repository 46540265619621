<template>
  <div class="container-gender">
    <h2
      id="title"
      style="color: black; font-size: 24px; font-weight: normal; margin: 0; margin-bottom: 24px;"
    >
      Gender
    </h2>
    <!-- Male Icon -->
    <el-row>
      <el-col span="12">
        <span
          id="male-icon"
          class="icon"
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 640.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <metadata>
              Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g
              transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
              fill="#6899D1"
              stroke="none"
            >
              <path
                d="M3027 12784 c-290 -52 -544 -220 -705 -463 -134 -204 -189 -425 -170
-681 30 -386 296 -743 659 -886 143 -56 212 -68 389 -69 168 0 209 6 340 47
263 83 515 309 630 562 124 273 129 581 13 856 -73 174 -231 368 -378 465
-233 154 -520 216 -778 169z"
              />
              <path
                d="M1920 10435 c-8 -2 -49 -9 -90 -15 -106 -17 -265 -71 -371 -126 -394
-204 -653 -566 -731 -1024 -10 -59 -13 -445 -13 -1815 l0 -1740 22 -71 c71
-223 311 -355 546 -300 161 38 267 129 328 281 l24 60 3 1553 2 1552 110 0
110 0 2 -4152 3 -4153 21 -61 c59 -169 154 -284 295 -353 190 -93 392 -93 586
0 152 73 269 220 314 394 10 40 14 536 16 2472 l3 2423 105 0 105 0 0 -2407
c0 -2080 2 -2418 15 -2478 61 -293 341 -494 655 -471 260 18 457 165 538 401
l27 80 3 4153 2 4153 108 -3 107 -3 5 -1555 c4 -1101 8 -1564 16 -1585 75
-204 232 -315 447 -315 234 0 413 158 447 395 8 58 10 541 8 1770 -3 1588 -5
1696 -22 1785 -110 572 -500 992 -1046 1128 l-105 26 -1290 2 c-709 1 -1297 1
-1305 -1z"
              />
            </g>
          </svg>
        </span>
      </el-col>
      <el-col span="12">
        <!-- Female Icon -->
        <span
          id="female-icon"
          class="icon"
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 640.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <metadata>
              Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g
              transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
              fill="#F7D470"
              stroke="none"
            >
              <path
                d="M3040 12786 c-438 -78 -765 -396 -854 -829 -19 -91 -22 -272 -6 -369
54 -340 298 -652 613 -785 165 -69 212 -78 417 -77 172 0 191 2 275 28 183 55
326 139 453 265 363 359 413 913 121 1337 -59 85 -180 204 -268 264 -92 62
-217 118 -329 146 -120 32 -309 40 -422 20z"
              />
              <path
                d="M2285 10435 c-293 -47 -542 -177 -751 -391 -178 -183 -280 -362 -367
-649 -30 -99 -115 -376 -187 -615 -73 -239 -181 -595 -240 -790 -59 -195 -167
-551 -240 -790 -72 -239 -150 -493 -172 -563 -71 -230 -81 -315 -53 -436 31
-130 117 -235 240 -293 118 -55 278 -49 385 14 49 29 126 105 160 158 38 59
54 104 149 425 419 1413 673 2267 707 2383 l25 82 110 0 c101 0 110 -2 105
-17 -10 -33 -122 -421 -326 -1128 -213 -738 -430 -1494 -660 -2290 -76 -264
-200 -693 -275 -954 -75 -260 -139 -480 -142 -487 -4 -12 100 -14 656 -14
l661 0 0 -1818 c0 -1263 3 -1836 11 -1878 43 -236 262 -398 514 -381 226 16
400 159 444 365 8 38 11 568 11 1892 l0 1840 145 0 144 0 3 -1857 c3 -1849 3
-1858 24 -1914 46 -126 117 -211 221 -267 147 -78 338 -81 484 -7 67 35 158
121 192 183 60 111 57 -5 57 2004 l0 1838 660 0 c368 0 660 4 660 9 0 4 -13
53 -29 107 -29 98 -99 339 -291 999 -169 582 -589 2029 -790 2720 -106 363
-218 749 -249 858 l-58 197 119 0 c86 0 120 -3 123 -12 2 -7 53 -179 114 -383
60 -203 168 -566 239 -805 72 -239 198 -664 282 -945 83 -280 165 -551 181
-602 35 -106 86 -190 153 -251 218 -200 568 -106 675 182 27 73 37 192 22 271
-6 33 -140 483 -297 1000 -717 2356 -657 2168 -740 2308 -185 308 -476 551
-779 651 -190 63 -140 60 -1085 62 -670 2 -883 0 -945 -11z"
              />
            </g>
          </svg>

        </span>
      </el-col>
    </el-row>

    <div class="bar-container">
      <span class="percentage">{{ malePercentage }}%</span>
      <div
        id="male-bar"
        class="bar"
        :style="{ width: malePercentage + '%' }"
      />
      <div
        id="female-bar"
        class="bar"
        :style="{ width: femalePercentage + '%' }"
      />
      <span class="percentage">{{ femalePercentage }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gender: {
      type: Array,
      required: true,
    },
  },
  computed: {
    malePercentage() {
      // Convert headcount to numbers and calculate the total
      const total = this.gender.reduce((sum, item) => sum + Number(item.headcount), 0);
      const maleData = this.gender.find(item => item.group === 'Male');
      return maleData ? ((Number(maleData.headcount) / total) * 100).toFixed(2) : 0;
    },
    femalePercentage() {
      return (100 - this.malePercentage).toFixed(2);
    },
  },
};
</script>

  <style scoped>
  .container-gender {
    text-align: center;
    color: grey;
  }

  #title {
    color: grey;
  }

  .bar-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
  }

  .bar {
    height: 100%;
  }

  .icon svg {
    display: inline-block;
}

  .icon {
    font-size: 40px;
    color: grey;
    margin: 0 8px;

  }

  #male-icon {
    color: #6899D1;
  }

  #female-icon {
    color: #F7D470;
  }

  #male-bar {
    background-color: #6899D1;
  }

  #female-bar {
    background-color: #F7D470;
  }

  .percentage {
    width: 50px;
    text-align: center;
    color: grey;
    font-weight: bold;
    margin: 0 8px;
  }

  @media (max-width: 768px) {
    #title {
      font-size: 20px;
      margin: 0
    }
  }

  @media (max-height: 800px) {
    #title {
      font-size: 20px;
      margin: 0
    }
  }
  </style>
