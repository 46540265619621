// BarChart.vue
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    chartTitle: {
      type: String,
      // default: 'Age',
    },
  },
  mounted() {
    const ageOrder = ['18-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64', '65-69'];

    const sortedChartData = [...this.chartData].sort((a, b) => ageOrder.indexOf(a.group) - ageOrder.indexOf(b.group));

    const labels = sortedChartData.map(item => item.group);
    const data = sortedChartData.map(item => parseFloat(item.ratio * 100).toFixed(2));

    const colors = ['#6899D1'];
    const backgroundColors = sortedChartData.map((_, index) => colors[index % colors.length]);

    this.renderChart(
      {
        labels,
        datasets: [
          {
            label: this.chartTitle,
            data,
            backgroundColor: backgroundColors,
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: Boolean(this.chartTitle),
          text: this.chartTitle,
          fontSize: 24,
          fontColor: 'black',
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: value => value + '%',
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
        type: this.horizontal ? 'horizontalBar' : 'bar',
      },
    );
  },
};
