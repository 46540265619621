import { HorizontalBar } from 'vue-chartjs';

export default {
  extends: HorizontalBar,
  props: {
    householdIncome: {
      type: Array,
      required: true,
    },
    chartTitle: {
      type: String,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              mirror: true,
              callback: function(value) {
                if (value.length > 20) {
                  return value.substr(0, 20) + '...';
                }
                return value;
              },
              align: 'start',
              padding: 150,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
            },
          }],
        },
        layout: {
          padding: {
            left: 150,
          },
        },
        title: {
          display: Boolean(this.chartTitle),
          text: this.chartTitle,
          fontColor: 'black',
          fontStyle: 'bold',
          fontSize: 24,
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              return `${data.labels[tooltipItem.index]}: ${tooltipItem.xLabel}`;
            },
          },
        },
      },
    };
  },
  mounted() {
    this.renderSortedChart();
  },
  methods: {
    renderSortedChart() {
      const aggregatedIncome = this.aggregateIncomeData(this.householdIncome);
      const sortedIncome = this.sortIncomeData(aggregatedIncome);
      const labels = sortedIncome.map(item => item.group);
      const data = sortedIncome.map(item => parseInt(item.headcount));

      this.renderChart({
        labels,
        datasets: [
          {
            backgroundColor: '#6899D1',
            data,
          },
        ],
      }, this.options);
    },
    aggregateIncomeData(data) {
      const incomeRanges = {
        lowerRange: ['Under $15,000', '$15,000 - $24,999', '$25,000 - $34,999', '$35,000 - $49,999'],
        middleRange: ['$100,000 - $149,999', '$150,000 - $159,999', '$160,000 - $164,999'],
      };

      const aggregatedData = data.reduce((acc, item) => {
        let group = item.group;
        const headcount = parseInt(item.headcount);

        if (incomeRanges.lowerRange.includes(group)) {
          group = '$0-$49,999';
        } else if (incomeRanges.middleRange.includes(group)) {
          group = '$100,000 - $164,999';
        }

        const existingItem = acc.find(i => i.group === group);
        if (existingItem) {
          existingItem.headcount += headcount;
        } else {
          acc.push({ group, headcount });
        }

        return acc;
      }, []);

      return aggregatedData;
    },
    sortIncomeData(data) {
      const customOrder = [
        '$0-$49,999',
        '$50,000 - $74,999',
        '$75,000 - $99,999',
        '$100,000 - $164,999',
        '$165,000 - $199,999',
        '$200,000 - $249,999',
        '$250,000+',
      ];

      return data.sort((a, b) => {
        return customOrder.indexOf(a.group) - customOrder.indexOf(b.group);
      });
    },
  },
};
