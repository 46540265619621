import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    chartTitle: {
      type: String,
      // default: 'Marital Status',
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: Boolean(this.chartTitle),
          text: this.chartTitle,  // this assumes you've passed a 'chartTitle' prop to your component
          fontColor: 'black',
          fontStyle: 'bold',
          fontSize: 24,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 30,
            fontSize: 16,
            fontStyle: 'bold',  // This makes the legend labels bold
          },
          layout: {
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,  // Adjust these values to move the legend closer
            },
          },
        },
      },
    };
  },
  mounted() {
    const labels = this.chartData.map(item => item.group);
    const data = this.chartData.map(item => item.headcount);

    this.renderChart(
      {
        labels: labels,
        datasets: [
          {
            backgroundColor: ['#E99A3E', '#6899D1', '#2B74B8', '#F7D470'],
            data: data,
          },
        ],
      },
      this.options,
    );
  },
  render: function(createElement) {
    return createElement(
      'div',
      [
        createElement('canvas', {
          ref: 'canvas',
        }),
      ],
    );
  },
};
